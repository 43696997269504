<template>
  <div class="main-wrap">
    <div class="banner-wrap">
      <banner-list :items="listBanner"></banner-list>
    </div>
    <!-- 新闻 -->
    <div class="g-wrap news-wrap" v-loading="loading">
      <div class="news">
        <column-header :data="newsColumn"></column-header>
        <div class="column-wrap">
          <slide-news :data="slideNews" indicatorPosition="inside"></slide-news>
          <top-news class="top-news" :topList="topNewsList"></top-news>
        </div>
      </div>
      <!-- 新闻右边 -->
      <div class="industry">
        <column-header :data="industryColumn"></column-header>
        <div class="column-wrap">
          <news :data="jobList" type="calendar"></news>
        </div>
      </div>
    </div>
    <!-- 岗位招聘 -->
    <div class="coop-wrap">
      <div class="g-wrap f-wrap" v-loading="loading">
        <div class="comp-column-header">
          <div class="left">
            <strong>岗位招聘</strong>
            <span>Selected posts</span>
          </div>
          <div class="right">
            <a @click="turnListJob">更多+</a>
          </div>
        </div>
        <div class="column-wrap">
          <job-card :cardList="listJob" />
        </div>
      </div>
    </div>
    <!-- 培训课程 -->
    <div class="coop-wrap" style="background: #fff;">
      <div class="g-wrap f-wrap my-wrap" v-loading="loading">
        <column-header :data="enterpriseColumn"></column-header>
        <div class="column-wrap">
          <div class="trainingCourse" v-for="(item, i) in CourseSeriesList" :key="i">
            <div class="CourseImgBox">
              <img class="CourseImg" v-real-img="item.CoverImage" src="@/assets/images/nodata.png" alt="" />
            </div>
            <div class="CourseTitle">{{ item.Name }}</div>
            <div class="CourseText">
              <div class="CourseText-left">{{ item.SignupCount }}人已学习</div>
              <div class="CourseText-right">
                <el-button type="text" icon="el-icon-video-play" size="medium" @click="$router.push('/course/detail?id=' + item.Id)">立即查看</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作企业 -->
    <div class="coop-wrap">
      <div class="g-wrap f-wrap my-wrap" v-loading="loading">
        <column-header :data="schoolColumn"></column-header>
        <div class="column-wrap">
          <el-carousel height="426px" arrow="never" class="card-carousel" :autoplay="false">
            <el-carousel-item>
              <school-card :cardList="enterpriseInfoList.slice(0, 8)" path="/cooperativeFirm/firmInfo?id=" :isType="true" />
            </el-carousel-item>
            <!-- <el-carousel-item v-if="listSchool.length > 8">
              <school-card :cardList="listSchool.slice(8, 16)" :isType="true" />
            </el-carousel-item>
            <el-carousel-item v-if="listSchool.length > 16">
              <school-card
                :cardList="listSchool.slice(16, 24)"
                :isType="true"
              />
            </el-carousel-item> -->
          </el-carousel>
          <!-- <school-card :cardList="listSchool" /> -->
        </div>
      </div>
    </div>
    <el-tooltip popper-class="contactus-tooltip" effect="light" placement="top" v-if="false">
      <div slot="content" class="contactus-prop">
        <span class="prop-title">校企合作咨询</span>
        <div class="prop-qrcode">
          <img style="width:114px;height:114px;object-fit: cover;" src="https://zxj.ve-city.com/files/zxj/portal/kf_qrcode.png" alt />
        </div>
        <span class="prop-text">扫码加微信咨询</span>
        <span class="prop-text">18676720290（李老师）</span>
      </div>
      <div class="contactus-wrap">
        <i class="iconfont iconkefuzixun contactus-icon"></i>
      </div>
    </el-tooltip>
    <AnswerTop />
  </div>
</template>

<script>
import AnswerTop from "@/components/AnswerTop";
import SlideNews from "@/components/SlideNews";
import TopNews from "@/components/TopNews";
import News from "@/components/News";
import BannerList from "@/components/BannerList";
import ColumnHeader from "@/components/ColumnHeader";
import SchoolCard from "@/components/Common/SchoolCard";
// import CompanyCard from "@/components/Common/CompanyCard";
import JobCard from "@/components/Common/JobCard";
import { getBannerPageList, getNewsPageList, addAccessLog, getHomeProjectPageList, getEnterpriseInfoPageList, getCourseSeriesPageList } from "@/api/home";
import { getPageList } from "@/api/schoolInfo";
import { getPageList as getEnterpriseList } from "@/api/enterprise/enterpriseInfo";
// getJobPageList
import { getPageList as getJobList } from "@/api/job";
export default {
  components: {
    SlideNews,
    TopNews,
    News,
    BannerList,
    ColumnHeader,
    SchoolCard,
    // CompanyCard,
    JobCard,
    AnswerTop
  },
  data() {
    return {
      loading: false,
      newsColumn: {
        name: "新闻公告",
        enName: "News",
        link: "/information"
      },
      industryColumn: {
        name: "合作项目",
        enName: "Notice",
        link: "/cooperativeFirm/CooperativeItem"
      },
      enterpriseColumn: {
        name: "培训课程",
        enName: "Training course",
        link: "course"
      },
      schoolColumn: {
        name: "合作企业",
        enName: "Cooperative college",
        link: "/cooperativeFirm"
      },
      jpbColumn: {
        name: "精选岗位",
        enName: "Selected posts",
        link: "/cooperation/school"
      },
      coopList: [],
      jobList: [],
      CourseSeriesList: [],
      enterpriseInfoList: [],
      slideNews: [],
      topNewsList: [],
      listBanner: [],
      searchZone: "1",
      searchKeyword: "",
      searchArea: [],
      listEnterprise: [],
      listSchool: [],
      listJob: [],
      listJobPageIndex: 1,
      listJobTotalCount: -1
    };
  },
  async created() {
    this.loading = true;
    this.getBanner();
    this.getNews();
    // await this.getSchools();
    // this.getEnterprises();
    this.getJobs();
    this.getCourseSeries();
    this.getEnterpriseInfo();
    this.getHomeProject();
    const log = {
      BusinessType: "Home",
      BusinessId: "",
      Client: "Portal"
    };
    addAccessLog(log);
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  },
  mounted() { },
  methods: {
    handleChangeArea(value) {
      console.log(value);
    },
    getBanner() {
      getBannerPageList().then(res => {
        if (res.IsSuccess) {
          this.listBanner = res.Result.Data;
        }
      });
    },
    getNews() {
      var query = {
        PageSize: 7,
        PageIndex: 1
      };
      // 首页资讯列表前4
      getNewsPageList(query).then(res => {
        if (res.IsSuccess) {
          this.topNewsList = res.Result.Data.slice(0, 4);
          this.slideNews = res.Result.Data.slice(0, 4);
        }
      });
    },
    getHomeProject() {
      var query = {
        PageSize: 4,
        PageIndex: 1
      };
      getHomeProjectPageList(query).then(res => {
        // console.log("项目", res);
        if (res.IsSuccess) {
          // /recruitment/recruit-detail?id=
          this.jobList = this.checkNews(res.Result.Data);
        }
      });
    },
    // 培训课程
    async getCourseSeries() {
      var query = {
        PageSize: 3,
        PageIndex: 1
      };
      try {
        const res = await getCourseSeriesPageList(query);
        if (res.IsSuccess) {
          // /recruitment/recruit-detail?id=
          this.CourseSeriesList = res.Result.Data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getEnterpriseInfo() {
      var query = {
        PageSize: 8,
        PageIndex: 1
      };
      try {
        const res = await getEnterpriseInfoPageList(query);
        if (res.IsSuccess) {
          // /recruitment/recruit-detail?id=
          this.enterpriseInfoList = res.Result.Data;
          // console.log(this.enterpriseInfoList);
        }
      } catch (error) {
        console.log(error);
      }
    },
    checkNews(list) {
      return list.map(el => {
        return {
          ...el,
          link: `/recruitment/recruit-detail?id=${el.Id}`
        };
      });
    },
    getSchools() {
      var query = {
        PageSize: 24,
        PageIndex: 1,
        Status: 2
      };
      getPageList(query).then(res => {
        if (res.IsSuccess) {
          this.listSchool = res.Result.Data;
        }
      });
    },
    getEnterprises() {
      var query = {
        PageSize: 24,
        PageIndex: 1,
        Status: 2
      };
      getEnterpriseList(query).then(res => {
        if (res.IsSuccess) {
          this.listEnterprise = res.Result.Data;
        }
      });
    },
    getJobs() {
      var query = {
        PageSize: 6,
        PageIndex: 1
      };
      getJobList(query).then(res => {
        // getJobPageList(query).then(res => {
        if (res.IsSuccess) {
          this.listJob = res.Result.Data;
          this.listJobTotalCount = res.Result.TotalCount;
        }
      });
    },
    turnListJob() {
      this.$router.push("/recruitment/recruit");
      // if (this.listJobPageIndex < this.listJobTotalCount / 6) {
      //   this.listJobPageIndex++;
      // } else {
      //   this.listJobPageIndex = 1;
      // }
      // var query = {
      //   PageSize: 6,
      //   PageIndex: this.listJobPageIndex
      // };
      // getJobList(query).then(res => {
      //   if (res.IsSuccess) {
      //     this.listJob = res.Result.Data;
      //     this.listJobTotalCount = res.Result.TotalCount;
      //   }
      // });
    },
    goAirSessions() {
      window.open("https://zxj.ve-city.com/sessions/", "_blank");
    },
    goCourse() {
      this.$message.info("建设中...");
    }
  }
};
</script>

<style lang="scss" scoped>
.main-wrap {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.search-wrap {
  display: flex;
  justify-content: center;
  .search-con {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
  margin-bottom: 20px;
}
.enterprise-wrap {
  min-height: 200px;
  display: flex;
  background-color: #f6f6f8;
  justify-content: center;
  .enterprise-con {
    .enterprise-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .enterprise {
        display: flex;
        background-color: #ffffff;
        width: 261px;
        height: 120px;
        justify-content: center;
        align-items: center;
        margin-bottom: 12px;
        cursor: pointer;
        &:hover {
          box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
.job-wrap {
  min-height: 200px;
  display: flex;
  background-color: #f6f6f8;
  justify-content: center;
  .job-con {
    .job-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .job {
        display: flex;
        background-color: #ffffff;
        width: 350px;
        height: 160px;
        justify-content: center;
        align-items: center;
        margin-bottom: 15px;
        cursor: pointer;
        &:hover {
          box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.05);
        }
      }
    }
  }
}
.coop-wrap {
  padding-bottom: 40px;
  background-color: #f6f6f9;
  display: flex;
  justify-content: center;
}
.column-wrap {
  display: flex;
  position: relative;
  width: 100%;
  .card-carousel {
    width: 100%;
    .el-carousel-item {
      width: 100%;
    }
  }
}
// 培训课程
.column-wrap {
  .trainingCourse {
    transition: all 0.1s;
    margin-top: 16px;
    width: 355px;
    height: 319px;
    background: #ffffff;
    box-shadow: 0px 0px 16px 0px rgba(51, 51, 51, 0.12);
    border-radius: 8px;
    overflow: hidden;
    &:hover {
      transform: scale(1.015);
    }
    &:nth-child(-n + 2) {
      margin-right: 17px;
    }
    .CourseImgBox {
      width: 355px;
      height: 198px;
      .CourseImg {
        width: 100%;
        height: 100%;
      }
    }
    .CourseTitle {
      box-sizing: border-box;
      width: 100%;
      height: 28px;
      padding-left: 16px;
      margin: 16px 0 0 0;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 28px;
    }
    .CourseText {
      box-sizing: border-box;
      width: 100%;
      padding: 0 15px 0 17px;
      margin-bottom: 18px;
      margin-top: 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .CourseText-left {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }
      /deep/ .el-button {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3d5afe;
        line-height: 14px;
      }
    }
  }
}

.card-turn-btn {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333333;
  color: #fff;
  opacity: 0.1;
  top: 164px;
  cursor: pointer;
  &:hover {
    opacity: 0.2;
  }
}
.card-right {
  right: -60px;
}
.card-left {
  left: -60px;
}
.news-wrap {
  display: flex;
  // @include clearfix();
  margin: 0 auto;
  justify-content: space-between;
  padding-bottom: 16px;
  .news {
    margin-right: 16px;
    flex-grow: 1;
  }
  .comp-slide-news {
    width: 356px;
    height: 264px;
  }
  .top-news {
    margin-left: 16px;
  }
  .comp-news {
    width: 432px;
    height: 288px;
  }
  .industry {
    box-sizing: border-box;
    width: 356px;
  }
}
.f-wrap {
  flex-wrap: wrap;
}
.service-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: -40px;
  z-index: 99;
}
.service-item {
  display: flex;
  // flex-direction: column;
  width: 260px;
  height: 120px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-bottom: 20px;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(51, 51, 51, 0.1);
  border-radius: 8px;
  border: 1px solid rgba(238, 238, 238, 1);
  border-radius: 8px;
  border: 1px solid #eeeeee;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 12px 0px rgba(51, 51, 51, 0.12);
    .service-title {
      color: #3d5afe;
    }
  }
  .service-title {
    margin-left: 16px;
    font-size: 20px;
    color: #333333;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    &-detail {
      height: 20px;
      margin-top: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
      color: #999999;
      &-headline {
        color: #3d5afe;
      }
    }
  }
}
.contactus-wrap {
  display: flex;
  position: fixed;
  right: 32px;
  bottom: 100px;
  width: 56px;
  height: 56px;
  border-radius: 36px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 0px rgba(1, 188, 119, 0.2);
  align-items: center;
  cursor: pointer;
  justify-content: center;
  .contactus-icon {
    font-size: 32px;
    color: #cccccc;
  }
  &:hover {
    background: #3d5afe;
    .contactus-icon {
      font-size: 32px;
      color: #ffffff;
    }
  }
}
.contactus-prop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 280px;
  box-sizing: border-box;
  padding: 8px;
  .prop-title {
    font-weight: 600;
    color: #3d5afe;
    font-size: 18px;
  }
  .prop-qrcode {
    width: 130px;
    height: 130px;
    background: #ffffff;
    margin-top: 16px;
    margin-bottom: 16px;
    border-radius: 6px;
    border: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prop-text {
    color: #666666;
    font-size: 14px;
    margin-bottom: 4px;
  }
}
::v-deep .el-carousel {
  width: 100%;
  .el-carousel__indicators {
    width: 100%;
    text-align: center;
  }
  .el-carousel__button {
    width: 32px;
    height: 6px;
    background: #dddddd;
    border-radius: 3px;
  }
  .is-active {
    .el-carousel__button {
      background: #3d5afe;
    }
  }
}
.comp-column-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 40px 0 16px 0;
  .left {
    strong {
      font-size: 18px;
      font-weight: 600;
      color: #3d5afe !important;
      line-height: 25px;
    }
    span {
      font-size: 14px;
      color: rgba(158, 158, 158, 1);
      line-height: 20px;
      margin-left: 8px;
      &::before {
        content: '/ ';
      }
    }
  }
  .right {
    margin-top: 4px;
    a {
      font-size: 14px;
      color: #3d5afe;
      line-height: 17px;
      text-decoration: none;
      cursor: pointer;
      i {
        margin-right: 2px;
      }
      &:hover {
        color: #3d5afe;
      }
    }
  }
}
.my-wrap {
  width: 1100px;
  justify-content: center;
  .comp-column-header {
    width: 1100px;
    padding-bottom: 0;
  }
}
</style>

<style lang="scss">
.el-tooltip__popper.is-light .popper__arrow {
  border-top-color: #fff !important;
}
</style>
