<template>
  <div class="banner-list-wrap">
    <el-carousel :height="height" indicator-position="none">
      <!-- <el-carousel-item>
        <div
          class="carouse_img hander"
          @click="handleClick('prop', '')"
          :style="
            `height: ${height};background-size: 1920px ${height};background-image: url(${require('@/assets/images/banner/home.png')});`
          "
        ></div>
      </el-carousel-item> -->
      <el-carousel-item v-for="(item, i) in items" :key="i">
        <div
          class="carouse_img hander"
          @click="handleClick('link', item)"
          :style="
            `height: ${height};background-size: 1920px ${height};background-image: url(${item.BannerImg});`
          "
        ></div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { openWindow } from "@/utils/tools";
export default {
  name: "BannerList",
  props: {
    items: {
      type: Array
    },
    height: {
      type: String,
      default: "400px"
    }
  },
  methods: {
    handleClick(showType, item) {
      if (item.LinkUrl) {
        var yourWindow = window.open();
        yourWindow.opener = null;
        if (item.LinkUrl.indexOf("http://") == -1) {
          yourWindow.location = "http://" + item.LinkUrl;
          yourWindow.target = "_blank";
        } else {
          yourWindow.location = item.LinkUrl;
          yourWindow.target = "_blank";
        }
      }
      // console.log(`it`, item);
      // openWindow(item.LinkUrl);
      // if (item.LinkUrl) {
      //   // window.open(it.LinkUrl, "_blank");
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
.carouse_item {
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.carouse_img {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  // background-image:url("/img/home/banner.png");
  // background: url("/img/home/banner.png") no-repeat center center;
}
.banner-list-wrap ::v-deep {
  .el-carousel {
    .el-carousel__indicators {
      width: 1100px;
      bottom: 72px;
      .el-carousel__button {
        height: 4px;
        border-radius: 2px;
      }
    }
  }
}
.hander {
  cursor: pointer;
}
.contact-title {
  display: flex;
  justify-content: center;
  font-weight: 600;
  color: #598df3;
  margin-top: 20px;
}
.contactus-prop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 8px;
  margin-bottom: 20px;
  .prop-title {
    font-weight: 600;
    color: #598df3;
    font-size: 18px;
  }
  .prop-qrcode {
    width: 180px;
    height: 180px;
    background: #ffffff;
    margin-bottom: 16px;
    border-radius: 6px;
    border: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prop-text {
    color: #666666;
    font-size: 14px;
    margin-bottom: 4px;
  }
}
</style>
