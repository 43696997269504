<template>
  <div class="comp-top-news">
    <div
      class="newsListBox"
      v-for="(item, i) in topList"
      :key="i"
      @click="$router.push('information/detail?id=' + item.Id)"
    >
      <div class="boxTop">
        <div class="boxTop-icon"></div>
        <span class="boxHeadline">{{ item.Headline }}</span>
      </div>
      <div class="boxBottom">
        {{ item.Summary }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "com-top-news",
  props: {
    topList: {
      type: Array
    }
  },
  methods: {
    handleClick(item) {
      console.log("item: ", item);
      this.$router.push(`/information/detail?id=${item.Id}`);
    }
  },
  mounted() {
    // console.log(this.topList);
  }
};
</script>

<style lang="scss" scoped>
.comp-top-news {
  width: 356px;
  height: 264px;
  .newsListBox {
    width: 100%;
    height: 25%;
    cursor: pointer;
    border-bottom: 1px solid #eee;
    padding: 15px 5px 16px 0;
    box-sizing: border-box;
    &:hover {
      .boxTop {
        .boxHeadline {
          color: #3d5afe;
        }
      }
      .boxBottom {
        color: #333333;
      }
    }
    .boxTop {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 14px;
      .boxTop-icon {
        width: 4px;
        height: 4px;
        margin-right: 8px;
        border-radius: 50%;
        background-color: #3d5afe;
      }
      .boxHeadline {
        height: 16px;

        display: inline-block;
        width: 100%;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 16px;
        white-space: nowrap; /*一行显示*/
        overflow: hidden; /*超出部分隐藏*/
        text-overflow: ellipsis; /*用...代替超出部分*/
      }
    }
    .boxBottom {
      height: 12px;
      margin-left: 12px;
      margin-top: 9px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
