<template>
  <div class="card-list">
    <div
      class="card-item cursor"
      v-for="(item, index) in cardList"
      :key="index"
      @click="handleClick(item.Id)"
    >
      <img
        style="width:72px; height: 72px;"
        v-real-img="item.Logo"
        src="@/assets/images/default_logo.png"
        alt=""
      />
      <h3>{{ item.EnterpriseName }}</h3>
      <div class="tags" v-if="isType">
        <span class="tags-txt">{{ item.RecuritJobCount }}</span
        >个在招职位
      </div>
      <!-- <div class="tags" v-else>民营 | 互联网</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "SchoolCard",
  props: {
    cardList: {
      type: Array,
      default: () => []
    },
    path: {
      type: String,
      default: "/"
    },
    isType: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick(id){
      sessionStorage.setItem("enterpriseId", id);
      this.$router.push(this.path +id)
    }
  }
};
</script>

<style lang="scss" scoped></style>
