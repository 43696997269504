<template>
  <div class="comp-column-header">
    <div class="left">
      <strong>{{ data.name }}</strong>
      <span>{{ data.enName }}</span>
    </div>
    <div class="right" v-if="data.link">
      <router-link :to="data.link" :target="data.target || '_self'"
        >更多+</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "column-header",
  props: {
    data: {
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.comp-column-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 32px 0 12px 0;
  .left {
    strong {
      font-size: 18px;
      font-weight: 600;
      color: #3d5afe;
      line-height: 25px;
    }
    span {
      font-size: 14px;
      color: rgba(158, 158, 158, 1);
      line-height: 20px;
      margin-left: 8px;
      &::before {
        content: "/ ";
      }
    }
  }
  .right {
    margin-top: 4px;
    a {
      font-size: 14px;
      color: #3d5afe;
      line-height: 17px;
      text-decoration: none;
      &:hover {
        color: #3d5afe;
      }
    }
  }
}
</style>
