<template>
  <div class="comp-slide-news">
    <el-carousel
      ref="carousel"
      height="264px"
      :indicator-position="indicatorPosition"
      @change="changeItem"
    >
      <el-carousel-item v-for="(item, i) in data" :key="i">
        <router-link
          :to="`/information/detail?id=${item.Id}`"
          :target="target"
          :title="item.Headline"
        >
          <img :src="item.Illustration" :alt="item.Headline" />
          <h3>
            <span class="title">{{ item.Headline }}</span>
          </h3>
        </router-link>
      </el-carousel-item>
    </el-carousel>
    <!-- <div class="nav">
      <a
        v-for="(item, i) in data"
        :class="{ active: activeIndex == i }"
        :key="i"
        @click="activeItem(i)"
        >{{ i + 1 }}</a
      >
    </div> -->
  </div>
</template>

<script>
export default {
  name: "ic-slide-news",
  props: {
    data: {
      type: Array
    },
    target: {
      type: String,
      default: "_self"
    },
    indicatorPosition: {
      type: String,
      default: "none"
    }
  },
  data() {
    return {
      activeIndex: 0
    };
  },
  methods: {
    activeItem(i) {
      this.$refs.carousel.setActiveItem(i);
    },
    changeItem(i) {
      this.activeIndex = i;
    }
  }
};
</script>

<style lang="scss" scoped>
.comp-slide-news {
  position: relative;
  width: 376px;
  height: 304px;
  border-radius: 4px;
  overflow: hidden;
  ::v-deep .el-carousel {
    width: 100%;
    .el-carousel__indicators {
      width: 100%;
      text-align: right;
    }
    .el-carousel__button {
      width: 12px;
      height: 4px;
      border-radius: 4px;
    }
    .is-active {
      .el-carousel__button {
        width: 20px;
        background: #3d5afe;
      }
    }
  }
  .nav {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    z-index: 9;
    a {
      color: #fff;
      display: inline-block;
      line-height: 32px;
      width: 32px;
      text-align: center;
      cursor: pointer;
    }
    a:hover,
    a.active {
      background-color: #598DF3;
    }
  }
  h3 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
    background-color: rgba($color: #000000, $alpha: 0.4);
    color: rgba(255, 255, 255, 1);
    line-height: 20px;
    font-weight: normal;
    padding: 6px 8px;
    font-size: 14px;
    .title {
      // @include ellipsis();
      max-width: calc(100% - 170px);
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
